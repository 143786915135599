<template>
  <div class="pb-4">
    <h4 class="mt-3 mb-2">
      Pagina non attiva in questa demo
    </h4>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <p>Questa pagina non è attiva in questa demo</span>, puoi continuare la navigazione sulle sezione disponibili in questa demo.</p>
        
        <div class="demo-inline-spacing pb-3">
          <b-button
          variant="primary"
          :to="{ name: 'catalogo-articoli'}"
          >
            Articoli
          </b-button>
          <b-button
          variant="primary"
          :to="{ name: 'catalogo-carrello'}"
          >
            Carrello
          </b-button>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert, BLink, BButton, } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BLink,
    BButton,
  },
  methods: {
    articoli(){
      console.log("cliccato Articoli .....")
      this.$router.replace('catalogo/articoli')
    }
  }
}
</script>
